@if (loading) {
  <div class="skeleton-tags">
    <p-skeleton height="40px" class="skeleton-tags" styleClass="mb-2" />
  </div>
} @else {
  <div class="tags-container">
    <span class="span-header">Properties</span>
    <div class="tags-list">
      @if (language) {
        <p-tag class="tag" icon="pi pi-language" [value]="language" />
      }
      @if (confidentiality) {
        <p-tag class="tag" icon="pi pi-lock" [value]="confidentiality" />
      }
      @if (documentType) {
        <p-tag class="tag" icon="pi pi-briefcase" [value]="documentType" />
      }
      @for (tag of tags; track tag) {
        @if (!tag?.ignored) {
          @if (tag?.display_as_suggested) {
            <app-suggested-tag
              [tag]="tag"
              (ignoreSuggestedTag)="ignoreSuggestedTag($event)"
            />
          } @else {
            <p-tag class="tag" icon="pi pi-tag" [value]="tag.tag?.label" />
          }
        }
      }
      @if (hasRights) {
        @for (customProperty of customProperties; track customProperty) {
          @for (option of customProperty.selected_options; track option) {
            <p-tag class="tag" [value]="option.name" />
          }
        }
      }

      @if (!language && !confidentiality && !documentType && !tags?.length) {
        <div class="no-property">
          <i class="pi pi-tags"></i>
          <span>There's no property yet.</span>
        </div>
      }
      @if (!locked && hasRights) {
        <div
          class="text-icon-button"
          (click)="editPropertiesCallback.emit(DialogState.PROPERTIES)"
        >
          <i class="pi pi-plus-circle"></i>
          <span>Edit properties</span>
        </div>
      }
    </div>
  </div>
}
