export const environment = {
  production: true,
  apiURL: 'https://trinity-staging.int.overlayer.ai/',
  pyroneURL: 'https://pyrone.int.overlayer.ai',
  googleTagManagerID: '',
  heapID: '',
  smartlookID: '',
  crispID: '',
  mrSatanURL: 'https://mrsatan-staging.int.overlayer.ai/',
};
