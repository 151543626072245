import {
  Heartbeat,
  SidePanel,
  Status,
  DialogState,
  LikeButtonData,
  Sections,
  SidePanelProperties,
} from './side-panel.types';
import {
  People,
  File,
  VerifiedVersion,
  Version,
  Rating,
  Tag,
  GoogleRessourceRole,
  LabelFieldOptionType,
  FileErrorReason,
  UnprocessedEntity,
  UnprocessedEntityReason,
  UpdateTagsOperation,
  TagDatamart,
  LabelType,
  LabelOption,
} from 'app/types';
import { Component, Injector, OnInit } from '@angular/core';
import { FileService } from 'app/services/file/file.service';
import { LikeService } from 'app/services/like/like.service';
import {
  LocalStorageService,
  USER_INFO_KEY,
} from 'app/services/local-storage/local-storage.service';
import { RatingService } from 'app/services/rating/rating.service';
import { TagService } from 'app/services/tag/tag.service';
import { lastValueFrom } from 'rxjs';
import { EventManager } from 'app/services/events/events.service';
import { AuthService } from 'app/services/auth/auth.service';
import { ToastService } from 'app/services/toast/toast.service';
import { VersioningService } from 'app/services/versioning/versioning.service';
import { GdrivesService } from 'app/services/gdrives/gdrives.service';
import {
  getGoogleDriveFileId,
  isError,
  openURLInNewTab,
} from 'app/utils/utils';
import { WorkflowUserRole } from 'app/types/workflow.types';
import { DescriptionDialogComponent } from './dialogs/description/description-dialog.component';
import { PropertiesDialogComponent } from './dialogs/properties/properties-dialog.component';
import { RatingDialogsComponent } from './dialogs/rating/rating-dialogs.component';
import { FlagDialogComponent } from './dialogs/flag/flag-dialog.component';
import { VersioningDialogComponent } from './dialogs/versioning/versioning-dialog.component';
import { WorkflowApprovalDialogComponent } from './dialogs/workflow-approval/workflow-approval-dialog.component';
import { MenuItem } from 'primeng/api';
import { BulkService } from 'app/services/bulk/bulk.service';
import { SensitivityComponent } from './dialogs/sensitivity/sensitivity.component';
import { CustomLabelService } from 'app/services/custom-label/custom-label.service';

@Component({
  selector: 'app-side-panel',
  templateUrl: './side-panel.component.html',
  styleUrl: './side-panel.component.scss',
})
export class SidePanelComponent implements OnInit {
  readonly DialogState = DialogState;
  readonly Sections = Sections;
  readonly sidePanelStatus = Status;
  readonly ellapsedTimeToRetryFileFetchingAfterFailure = 30000;
  readonly dialogs = {
    [DialogState.DESCRIPTION]: {
      component: DescriptionDialogComponent,
      injector: Injector.create({
        providers: [],
      }),
    },
    [DialogState.PROPERTIES]: {
      component: PropertiesDialogComponent,
      injector: Injector.create({
        providers: [],
      }),
    },
    [DialogState.RATING]: {
      component: RatingDialogsComponent,
      injector: Injector.create({
        providers: [],
      }),
    },
    [DialogState.FLAG]: {
      component: FlagDialogComponent,
      injector: Injector.create({
        providers: [],
      }),
    },
    [DialogState.VERSIONING]: {
      component: VersioningDialogComponent,
      injector: Injector.create({
        providers: [
          {
            provide: 'OUTPUTS',
            useValue: {
              createDraft: (linkedGoogleUrl?: string) =>
                this.createNewVersion(linkedGoogleUrl),
            },
          },
        ],
      }),
    },
    [DialogState.WORKFLOW]: {
      component: WorkflowApprovalDialogComponent,
      injector: Injector.create({
        providers: [
          {
            provide: 'OUTPUTS',
            useValue: {
              approveDocument: (confidentiality: string, date: Date) =>
                this.approveWorkflow(confidentiality, date),
            },
          },
        ],
      }),
    },
    [DialogState.SECRET]: {
      component: SensitivityComponent,
      injector: Injector.create({
        providers: [
          {
            provide: 'OUTPUTS',
            useValue: {
              applySensitivity: (confidentiality: string) =>
                this.updateFileConfidentialityOnSecret(confidentiality),
            },
          },
        ],
      }),
    },
    [DialogState.NONE]: { component: null, injector: undefined },
  };

  readonly dialogsHeader = {
    [DialogState.DESCRIPTION]: 'Edit description',
    [DialogState.PROPERTIES]: 'Edit properties',
    [DialogState.RATING]: 'Select your rating',
    [DialogState.FLAG]: 'Why are you flagging this?',
    [DialogState.VERSIONING]: 'Add version',
    [DialogState.WORKFLOW]: 'Last step - Confidentiality & Expiration date',
    [DialogState.SECRET]: 'Set file sensitivity',
    [DialogState.NONE]: '',
  };

  sidePanel: SidePanel = this.getDefaultSidePanel();
  docIDFromExtension: string | null = null;
  dialogToDisplay: DialogState = DialogState.NONE;
  isDocumentRatedByMe: boolean = false;
  splitButtonItems: MenuItem[] = [];
  likeButtonData?: LikeButtonData = undefined;
  lockedFileClass: string = '';
  hasRights: boolean = false;
  workflowRights: boolean = false;
  documentConfidentiality: string = '';
  lockedTosterVisible: boolean = true;

  constructor(
    private ratingService: RatingService,
    private tagService: TagService,
    private likeService: LikeService,
    private fileService: FileService,
    private bulkService: BulkService,
    private localStorageService: LocalStorageService,
    private authService: AuthService,
    private toastService: ToastService,
    private versioningService: VersioningService,
    private gdriveService: GdrivesService,
    private customLabelService: CustomLabelService,
  ) {
    this.getConnectedUser();
  }

  ngOnInit(): void {
    this.sidePanel.status = Status.LOADING;
    // If after 2 seconds the docIDFromExtension is still null, we have no document selected
    setTimeout(() => {
      if (!this.docIDFromExtension) {
        this.sidePanel.status = Status.UNKNOWN;
      }
    }, 2000);

    // Heartbeat answer extension
    EventManager.on('message', (event) => {
      switch (event.data.identifier) {
        case Heartbeat.EXTENSION_HEARTBEAT:
          window.parent.postMessage(
            {
              reason: 'heartbeat',
              identifier: Heartbeat.APP_HEARTBEAT,
              sentAt: Date.now(),
            },
            '*',
          );
          break;
        case Heartbeat.EXTENSION_DOCUMENT:
          this.loadSidePanelData(event.data.docId);
          break;
        default:
          break;
      }
    });
  }

  getConnectedUser() {
    const user = this.localStorageService.getItem<People>(USER_INFO_KEY);

    if (user) this.sidePanel.connectedUser = user as any;
    else {
      this.authService.getUser().subscribe((user: any) => {
        this.sidePanel.connectedUser = user.user;
        this.localStorageService.setItem<People>(USER_INFO_KEY, user.user);
      });
    }
  }

  getDefaultSidePanel(): SidePanel {
    return {
      connectedUser: undefined,
      status: Status.LOADING,
      loadingSections: [],
      tags: [],
      file: undefined,
      workflows: undefined,
      verifiedHistory: undefined,
      customLabels: [],
    };
  }

  reloadSidePanel() {
    this.loadSidePanelData(this.docIDFromExtension, true);
  }

  async loadSidePanelData(documentId: string | null, forceRefresh?: boolean) {
    if (!documentId) {
      this.sidePanel.status = Status.UNKNOWN;
      this.sidePanel.file = undefined;
      return;
    }
    if (
      (documentId !== this.sidePanel.file?.id &&
        documentId !== this.docIDFromExtension) ||
      forceRefresh
    ) {
      this.sidePanel = this.getDefaultSidePanel();
      this.getConnectedUser();
      this.docIDFromExtension = documentId;
      this.getTags();
      this.getCustomLabels();
      this.getFileData(documentId);
      this.getWorkflow(documentId);
      this.getVerifiedHistory(documentId);
    }
  }

  async getTags() {
    // Fetch tags list
    this.tagService.get().subscribe({
      next: (tagsData) => {
        this.sidePanel.tags = tagsData?.tags;
        this.dialogs[DialogState.PROPERTIES].injector = Injector.create({
          providers: [
            { provide: 'INPUTS', useValue: { tags: tagsData?.tags } },
            {
              provide: 'OUTPUTS',
              useValue: {
                cancelPropertiesDialog: () =>
                  this.toggleDialog(DialogState.NONE),
              },
            },
          ],
        });
      },
      error: (e) => {
        this.toastService.error({ summary: 'Could not get tags' });
      },
    });
  }

  async getCustomLabels() {
    // Fetch custom labels list
    this.customLabelService.getCustomLabels().subscribe({
      next: (customLabels) => {
        this.sidePanel.customLabels = customLabels;
      },
      error: () => {
        this.toastService.error({ summary: 'Could not get custom properties' });
      },
    });
  }

  // DECIDE WITH THE TEAM WHAT TO DO WITH CONFIDENTIALITY AS A LABEL
  fillConfidentiality(type: LabelFieldOptionType) {
    switch (type) {
      case LabelFieldOptionType.FILE_CONFIDENTIALITY_PUBLIC:
        this.documentConfidentiality = 'Public';
        break;
      case LabelFieldOptionType.FILE_CONFIDENTIALITY_INTERNAL:
        this.documentConfidentiality = 'Internal';
        break;
      case LabelFieldOptionType.FILE_CONFIDENTIALITY_CONFIDENTIAL:
        this.documentConfidentiality = 'Confidential';
        break;
    }
  }

  async getFileData(documentId?: string) {
    if (!documentId) return;
    this.dialogToDisplay = DialogState.NONE;
    this.fileService.get(documentId).subscribe({
      next: (response) => {
        const file = response.file;
        let flagged = false;
        this.sidePanel.file = file;
        this.hasRights = !(
          response?.user_permissions === GoogleRessourceRole.READER ||
          response?.user_permissions === GoogleRessourceRole.COMMENTER
        );
        this.setDescriptionProvider(file?.datamart?.description);
        this.setRatingProvider(file?.ratings);
        this.setFlagProvider();
        file.labels?.forEach((label) => {
          label.selected_options.forEach((option) => {
            if (option.type === LabelFieldOptionType.NEEDS_UPDATE_FLAGGED) {
              flagged = true;
            }
            this.fillConfidentiality(option.type);
          });
        });
        this.setPropertiesProvider(file);
        this.lockedFileClass = file.is_locked ? 'locked-file' : '';
        this.setSplitItems(flagged);
        this.initLikeButton();
        this.sidePanel.status = Status.FILLED;
        this.unsetFromLoadingSection([
          Sections.TITLE,
          Sections.DESCRIPTION,
          Sections.PROPERTIES,
          Sections.RATINGS,
          Sections.LABELS,
          Sections.LIKE,
        ]);
        setTimeout(() => {
          this.lockedTosterVisible = false;
          this.lockedFileClass = '';
        }, 3000);
      },
      error: (e: any) => {
        if (isError(e?.error?.statusCode, 400)) {
          if (e?.error?.message.reason === FileErrorReason.MY_DRIVE) {
            this.sidePanel.status = Status.MY_DRIVE;
          } else if (
            e?.error?.message.reason ===
            FileErrorReason.NOT_WATCHED_BY_OVERLAYER
          ) {
            this.sidePanel.status = Status.FILE_NOT_WATCHED;
          } else if (e?.error?.message.reason === FileErrorReason.NOT_FOUND) {
            this.sidePanel.status = Status.NOT_FOUND;
          } else if (e?.error?.message.reason === FileErrorReason.IS_SECRET) {
            this.sidePanel.status = Status.SECRET_FILE;
          }
        } else {
          if (this.sidePanel.status !== Status.UNKNOWN) {
            this.toastService.error({
              summary: `Could not get file: ${e?.error?.message?.message}`,
            });
          }
          this.sidePanel.status = Status.UNKNOWN;
        }
      },
    });
  }

  async getWorkflow(documentId: string) {
    this.fileService.getWorkflows(documentId).subscribe({
      next: ({ workflows }) => {
        this.sidePanel.workflows = workflows;
        if (workflows.length) {
          this.workflowRights = workflows[0].related_users.some(
            (relatedUser) =>
              relatedUser.user.primary_email ==
                this.sidePanel.connectedUser?.primary_email &&
              relatedUser.role == WorkflowUserRole.PENDING_VERIFIER,
          );
        }
        this.unsetFromLoadingSection([Sections.WORKFLOW]);
      },
      error: (e: any) => {},
    });
  }

  unsetFromLoadingSection(sections: Sections[]) {
    this.sidePanel.loadingSections = this.sidePanel.loadingSections.filter(
      (section) => !sections.includes(section),
    );
  }

  // SCREEN STATUS SECTION //
  watchDriveFromFileId() {
    if (!this.docIDFromExtension) return;
    this.gdriveService.watchDriveFromFileId(this.docIDFromExtension).subscribe({
      next: () => {
        this.loadSidePanelData(this.docIDFromExtension, true);
      },
      error: (e) => {
        this.toastService.error({
          summary: 'Could not add Overlayer to the shared drive',
        });
      },
    });
  }
  // DESCRIPTION SECTION //
  setDescriptionProvider(description?: string) {
    this.dialogs[DialogState.DESCRIPTION].injector = Injector.create({
      providers: [
        { provide: 'INPUTS', useValue: { description: description } },
        {
          provide: 'OUTPUTS',
          useValue: {
            cancelDescriptionDialog: () => this.toggleDialog(DialogState.NONE),
            saveDescriptionDialog: (description: string) =>
              this.updateDocumentDescription(description),
          },
        },
      ],
    });
  }

  updateDocumentDescription(description?: string) {
    if (!this.sidePanel.file?.id || !description) return;
    this.toggleDialog(DialogState.NONE);
    this.bulkService
      .updateFilesProperties({
        file_ids: [this.sidePanel.file.id],
        document_type: this.sidePanel.file?.datamart?.document_type,
        language: this.sidePanel.file?.datamart?.language,
        description: description,
      })
      .subscribe({
        next: ({ failed }) => {
          this.sidePanel.loadingSections.push(Sections.DESCRIPTION);
          if (this.bulkFailedNotEnoughPermission(failed)) {
            this.sidePanel.status = Status.OVERLAYER_NOT_ENOUGH_PERMISSION;
          } else if (this.sidePanel.file?.id) {
            this.getFileData(this.sidePanel.file.id);
          }
        },
        error: (e) => {
          this.toastService.error({
            summary: 'Could not update file description',
          });
        },
      });
  }

  hasAScreenStatusToDisplay() {
    const displayableStatus = [
      this.sidePanelStatus.UNKNOWN,
      this.sidePanelStatus.MY_DRIVE,
      this.sidePanelStatus.FILE_NOT_WATCHED,
      this.sidePanelStatus.LOADING,
      this.sidePanelStatus.NOT_FOUND,
      this.sidePanelStatus.SECRET_FILE,
      this.sidePanelStatus.OVERLAYER_NOT_ENOUGH_PERMISSION,
    ];
    return displayableStatus.includes(this.sidePanel.status);
  }
  // PROPERTIES SECTION //
  setPropertiesProvider(file: File) {
    this.dialogs[DialogState.PROPERTIES].injector = Injector.create({
      providers: [
        {
          provide: 'INPUTS',
          useValue: {
            tags: this.sidePanel.tags,
            fileTags: file.tags
              ?.filter((tag) => !tag.ignored)
              .map((tag) => tag.tag),
            fileConfidentiality: this.documentConfidentiality,
            fileType: file?.datamart?.document_type,
            fileLanguage: file?.datamart?.language,
            customLabels: this.sidePanel.customLabels,
            fileCustomLabels: file.labels?.filter(
              (fileLabel) => fileLabel.label.type === LabelType.CUSTOM,
            ),
            hasRight: this.hasRights,
          },
        },
        {
          provide: 'OUTPUTS',
          useValue: {
            cancelPropertiesDialog: () => this.toggleDialog(DialogState.NONE),
            savePropertiesDialog: (properties: SidePanelProperties) =>
              this.updateDocumentProperties(properties),
          },
        },
      ],
    });
  }

  async updateDocumentProperties(properties: SidePanelProperties) {
    this.sidePanel.loadingSections.push(Sections.PROPERTIES);
    this.saveDocumentLanguageAndDocumentType(
      properties.language,
      properties.documentType,
    );
    this.saveDocumentConfidentiality(properties.confidentiality);
    this.saveDocumentCustomLabels(properties.customPropertiesOptions);
    await this.saveDocumentTags(properties.tags);
    this.toggleDialog(DialogState.NONE);
  }

  ignoreSuggestedTag(tag?: TagDatamart) {
    if (!this.sidePanel.file?.id || !tag?.tag?.id) return;
    this.bulkService
      .updateFileTags({
        file_ids: [this.sidePanel.file.id],
        tag_ids: [],
        tags_to_ignore: [tag.tag.id],
        operation: UpdateTagsOperation.MERGE,
      })
      .subscribe({
        next: ({ failed }) => {
          if (this.bulkFailedNotEnoughPermission(failed)) {
            this.sidePanel.status = Status.OVERLAYER_NOT_ENOUGH_PERMISSION;
          } else if (this.sidePanel.file?.id) {
            this.sidePanel.loadingSections.push(Sections.PROPERTIES);
            this.getFileData(this.sidePanel.file.id);
            this.getTags();
          }
        },
        error: () => {
          this.toastService.error({
            summary: 'Could not remove suggested tag',
          });
        },
      });
  }

  updateFileConfidentialityOnSecret(confidentiality: string) {
    this.toggleDialog(DialogState.NONE);
    this.sidePanel.status = Status.LOADING;
    this.saveDocumentConfidentiality(confidentiality);
  }

  saveDocumentConfidentiality(confidentiality: string) {
    const docId = this.sidePanel.file?.id ?? this.docIDFromExtension;

    if (
      (!confidentiality || !this.sidePanel.file?.id) &&
      !this.docIDFromExtension
    )
      return;
    this.bulkService
      .updateFilesConfidentiality({
        file_ids: [docId as string],
        confidentiality:
          (confidentiality?.toLocaleLowerCase?.() ?? '') || undefined,
      })
      .subscribe({
        next: ({ failed }) => {
          if (this.bulkFailedNotEnoughPermission(failed)) {
            this.sidePanel.status = Status.OVERLAYER_NOT_ENOUGH_PERMISSION;
          } else if (docId) {
            this.getFileData(docId);
          }
        },
        error: (e) => {
          this.toastService.error({
            summary: 'Could not set confidentiality.',
          });
        },
      });
  }

  bulkFailedNotEnoughPermission(
    failed: UnprocessedEntity<string>[] | undefined,
  ) {
    return failed?.some(
      (fail) => fail.reason === UnprocessedEntityReason.NOT_ENOUGH_PERMISSIONS,
    );
  }

  saveDocumentLanguageAndDocumentType(language: string, documentType: string) {
    if (!this.sidePanel.file?.id) return;
    if (!language && !documentType) return;

    this.bulkService
      .updateFilesProperties({
        file_ids: [this.sidePanel.file.id],
        language: language ?? this.sidePanel.file?.datamart?.language,
        document_type:
          (documentType ??
            this.sidePanel.file?.datamart?.document_type ??
            '') ||
          undefined,
        description: this.sidePanel.file?.datamart?.description,
      })
      .subscribe({
        next: ({ failed }) => {
          if (this.bulkFailedNotEnoughPermission(failed)) {
            this.sidePanel.status = Status.OVERLAYER_NOT_ENOUGH_PERMISSION;
          } else if (this.sidePanel.file?.id) {
            this.getFileData(this.sidePanel.file.id);
          }
        },
        error: (e) => {
          this.toastService.error({
            summary: 'Could not update file properties.',
          });
        },
      });
  }

  saveDocumentCustomLabels(customLabelsOptions: LabelOption[]) {
    if (!this.sidePanel.file?.id) return;

    const customLabelsOptionsMap = customLabelsOptions.reduce((map, { id }) => {
      const labelOptionId = id.split('+', 2).join('+');
      if (!map.has(labelOptionId)) {
        map.set(labelOptionId, []);
      }
      map.get(labelOptionId)?.push(id);
      return map;
    }, new Map<string, string[]>());

    const selectedCustomLabels = Array.from(
      customLabelsOptionsMap,
      ([field_id, option_ids]) => ({
        field_id,
        option_ids,
      }),
    );

    this.bulkService
      .updateFilesCustomLabels([this.sidePanel.file.id], selectedCustomLabels)
      .subscribe({
        next: () => {},
        error: (error) => {
          this.toastService.error({
            summary: 'Could not update custom properties.',
          });
        },
      });
  }

  async saveDocumentTags(tags: Tag[]) {
    if (!this.sidePanel.file?.id) return;
    /** Update the document tags */
    const tagsIds: number[] = [];
    const tagsPromises: Promise<any>[] = [];

    this.sidePanel.loadingSections.push(Sections.PROPERTIES);

    /** Loop over the tags to create the new ones and add all ids in the array */
    tags.forEach((tag: Tag) => {
      if (tag.id !== -1) {
        tagsIds.push(tag.id);
      } else {
        tagsPromises.push(
          lastValueFrom(this.tagService.create(tag.label)).then((response) => {
            if (response.tag?.id) {
              tagsIds.push(response.tag.id);
            }
          }),
        );
      }
    });
    await Promise.all(tagsPromises);
    this.bulkService
      .updateFileTags({
        file_ids: [this.sidePanel.file.id],
        tag_ids: tagsIds,
        tags_to_ignore: [],
        operation: UpdateTagsOperation.REPLACE,
      })
      .subscribe({
        next: ({ failed }) => {
          if (this.bulkFailedNotEnoughPermission(failed)) {
            this.sidePanel.status = Status.OVERLAYER_NOT_ENOUGH_PERMISSION;
          } else if (this.sidePanel.file?.id) {
            this.getFileData(this.sidePanel.file.id);
            this.getTags();
          }
        },
        error: (e: any) => {
          this.toastService.error({
            summary: 'Could not set tags',
          });
        },
      });
  }

  // RATING SECTION //
  setRatingProvider(ratings?: Rating[]) {
    let userRating = 0;

    ratings?.forEach((rating) => {
      if (
        rating.rated_by.primary_email ===
        this.sidePanel.connectedUser?.primary_email
      ) {
        userRating = rating.rating;
        this.isDocumentRatedByMe = true;
      }
    });
    this.dialogs[DialogState.RATING].injector = Injector.create({
      providers: [
        { provide: 'INPUTS', useValue: { rating: userRating } },
        {
          provide: 'OUTPUTS',
          useValue: {
            applyRating: (rating: number) => this.updateRating(rating),
          },
        },
      ],
    });
  }

  updateRating(rating: number) {
    if (!this.sidePanel.file?.id) return;
    this.toggleDialog(DialogState.NONE);
    this.sidePanel.loadingSections.push(Sections.RATINGS);
    this.sidePanel.loadingSections.push(Sections.TITLE);
    this.ratingService
      .rateFile({
        file_id: this.sidePanel.file?.id,
        rating: rating,
      })
      .subscribe({
        next: () => {
          if (this.sidePanel.file?.id) {
            this.getFileData(this.sidePanel.file.id);
          }
        },
        error: () => {
          this.sidePanel.loadingSections = [];
          this.toastService.error({
            summary: 'Could not rate document',
          });
        },
      });
  }

  // WORKFLOW SECTION //
  startWorkflow() {
    if (!this.sidePanel.file?.id) return;
    this.sidePanel.loadingSections.push(Sections.WORKFLOW);
    this.bulkService
      .requestFileVerification([this.sidePanel.file?.id])
      .subscribe({
        next: ({ failed }) => {
          if (this.bulkFailedNotEnoughPermission(failed)) {
            this.sidePanel.status = Status.OVERLAYER_NOT_ENOUGH_PERMISSION;
          } else if (this.sidePanel.file?.id) {
            this.getWorkflow(this.sidePanel.file.id);
            this.getFileData(this.sidePanel.file.id);
          }
        },
        error: () => {
          this.sidePanel.loadingSections = [];
          this.toastService.error({
            summary: 'Could not start workflow',
          });
        },
      });
  }

  approveWorkflow(confidentiality: string, date?: Date) {
    if (!this.sidePanel.file?.id) return;
    this.toggleDialog(DialogState.NONE);
    this.sidePanel.loadingSections.push(Sections.WORKFLOW);
    this.sidePanel.loadingSections.push(Sections.LABELS);
    this.sidePanel.loadingSections.push(Sections.VERSIONING);
    this.bulkService
      .approveFileWorkflows(
        [this.sidePanel.file?.id],
        confidentiality.toLowerCase(),
        date ? new Date(date).toISOString() : null,
      )
      .subscribe({
        next: ({ failed }) => {
          if (this.bulkFailedNotEnoughPermission(failed)) {
            this.sidePanel.status = Status.OVERLAYER_NOT_ENOUGH_PERMISSION;
          } else if (this.sidePanel.file?.id) {
            this.getWorkflow(this.sidePanel.file.id);
            this.getFileData(this.sidePanel.file.id);
            this.getVerifiedHistory(this.sidePanel.file.id);
          }
        },
        error: (e) => {
          this.sidePanel.loadingSections = [];
          this.toastService.error({
            summary: 'Could not approve file',
          });
        },
      });
  }

  rejectWorkflow() {
    if (!this.sidePanel.file?.id) return;
    this.sidePanel.loadingSections.push(Sections.WORKFLOW);
    this.bulkService.rejectFileWorkflows([this.sidePanel.file?.id]).subscribe({
      next: ({ failed }) => {
        if (this.bulkFailedNotEnoughPermission(failed)) {
          this.sidePanel.status = Status.OVERLAYER_NOT_ENOUGH_PERMISSION;
        } else if (this.sidePanel.file?.id) {
          this.getWorkflow(this.sidePanel.file.id);
          this.getFileData(this.sidePanel.file.id);
        }
      },
      error: (e) => {
        this.sidePanel.loadingSections = [];
        this.toastService.error({
          summary: 'Could not reject file',
        });
      },
    });
  }

  exportPdf(id: number) {
    if (!id) return;
    this.fileService.getDownloadPDFURL(id).subscribe({
      next: ({ url }) => {
        openURLInNewTab(url);
      },
      error: () => {
        this.toastService.error({
          summary: 'Could not download file',
        });
      },
    });
  }

  // VERSIONING SECTION //
  createNewVersion(linkedGoogleUrl?: string) {
    if (linkedGoogleUrl) {
      this.createDraftFromExistingFile(linkedGoogleUrl);
    } else {
      this.createDraftFromCurrentFile();
    }
  }

  createDraftFromCurrentFile() {
    if (!this.sidePanel.file?.id) return;
    this.toggleDialog(DialogState.NONE);
    this.sidePanel.loadingSections.push(Sections.VERSIONING);
    this.versioningService.createDraft(this.sidePanel.file?.id).subscribe({
      next: (response) => {
        if (this.sidePanel.file?.id) {
          this.getVerifiedHistory(this.sidePanel.file.id);
        }
        openURLInNewTab(`https://docs.google.com/open?id=${response.file?.id}`);
      },
      error: () => {
        this.toastService.error({
          summary: 'Could not create a new version',
        });
        this.sidePanel.loadingSections = [];
      },
    });
  }

  createDraftFromExistingFile(linkedGoogleUrl: string) {
    if (!this.sidePanel.file?.id) return;

    const fileId = getGoogleDriveFileId(linkedGoogleUrl);
    if (fileId === null) {
      this.toastService.error({
        summary: 'The given url is not a google drive url',
      });
      return;
    }
    if (this.sidePanel?.verifiedHistory?.history?.id) {
      this.toggleDialog(DialogState.NONE);
      this.sidePanel.loadingSections.push(Sections.VERSIONING);
      this.versioningService
        .attachFileToHistory(this.sidePanel.verifiedHistory.history.id, fileId)
        .subscribe({
          next: (response) => {
            if (this.sidePanel.file?.id) {
              this.getVerifiedHistory(this.sidePanel.file.id);
            }
            openURLInNewTab(linkedGoogleUrl);
          },
          error: (e: any) => {
            this.toastService.error({
              summary: 'Could not link file to a new version',
            });
          },
        });
    } else {
      this.toastService.error({
        summary: 'Could not link file to a new version',
      });
    }
  }

  // LIKE SECTION //
  likeDocument() {
    if (!this.sidePanel.file?.id) return;
    this.sidePanel.loadingSections.push(Sections.LIKE);
    this.likeService.like(this.sidePanel.file?.id).subscribe({
      next: () => {
        this.getFileData(this.sidePanel.file?.id);
      },
      error: () => {
        this.sidePanel.loadingSections = [];
        this.toastService.error({
          summary: 'Could not like document',
        });
      },
    });
  }

  dislikeDocument() {
    if (!this.sidePanel.file?.id) return;
    this.sidePanel.loadingSections.push(Sections.LIKE);
    this.likeService.unlike(this.sidePanel.file?.id).subscribe({
      next: () => {
        this.getFileData(this.sidePanel.file?.id);
      },
      error: () => {
        this.sidePanel.loadingSections = [];
        this.toastService.error({
          summary: 'Could not remove like on document',
        });
      },
    });
  }

  // FLAG SECTION //
  setFlagProvider() {
    this.dialogs[DialogState.FLAG].injector = Injector.create({
      providers: [
        {
          provide: 'OUTPUTS',
          useValue: {
            cancelFlagDialog: () => this.toggleDialog(DialogState.NONE),
            flagDocument: (reason: string) => this.flagDocument(reason),
          },
        },
      ],
    });
  }

  flagDocument(reason: string) {
    if (!this.sidePanel.file?.id) return;
    this.sidePanel.loadingSections.push(Sections.LABELS);
    this.toggleDialog(DialogState.NONE);
    this.bulkService.flagFiles([this.sidePanel.file?.id], reason).subscribe({
      next: ({ failed }) => {
        if (this.bulkFailedNotEnoughPermission(failed)) {
          this.sidePanel.status = Status.OVERLAYER_NOT_ENOUGH_PERMISSION;
        } else if (this.sidePanel.file?.id) {
          this.getFileData(this.sidePanel.file.id);
        }
      },
      error: (e: any) => {
        this.toastService.error({
          summary: 'Could not flag document',
        });
      },
    });
  }

  unflagDocument() {
    if (!this.sidePanel.file?.id) return;
    this.sidePanel.loadingSections.push(Sections.LABELS);
    this.bulkService.unflagFiles([this.sidePanel.file?.id]).subscribe({
      next: ({ failed }) => {
        if (this.bulkFailedNotEnoughPermission(failed)) {
          this.sidePanel.status = Status.OVERLAYER_NOT_ENOUGH_PERMISSION;
        } else if (this.sidePanel.file?.id) {
          this.getFileData(this.sidePanel.file.id);
        }
      },
      error: (e: any) => {
        this.toastService.error({
          summary: 'Could not unflag document',
        });
      },
    });
  }

  // LOCKED FILE SECTION //
  unlockFile() {
    if (!this.sidePanel.file?.id) return;
    this.fileService.unlockFile(this.sidePanel.file?.id).subscribe({
      next: () => {
        if (this.sidePanel.file?.id) {
          this.loadSidePanelData(this.sidePanel.file.id, true);
        }
        this.toastService.success({
          summary: 'File has been unlocked',
        });
      },
      error: (e) => {
        if (e.error.statusCode === 409) {
          if (this.sidePanel.file?.id) {
            this.loadSidePanelData(this.sidePanel.file.id, true);
          }
          this.toastService.success({
            summary: 'File has been unlocked',
          });
        } else {
          this.toastService.error({
            summary: 'Could not unlock file',
          });
        }
      },
    });
  }

  // FOOTER BUTTONS SECTION //
  initLikeButton() {
    this.likeButtonData = {
      likedByMe: false,
      likesCount: this.sidePanel.file?.likes?.length ?? 0,
      onClick: () => {
        if (this.sidePanel.file?.likes?.length) {
          this.dislikeDocument();
        } else {
          this.likeDocument();
        }
      },
    };

    this.sidePanel.file?.likes?.forEach((like) => {
      if (
        like?.liked_by?.primary_email ===
          this.sidePanel.connectedUser?.primary_email &&
        this.likeButtonData
      ) {
        this.likeButtonData.likedByMe = true;
      }
    });
  }

  setSplitItems(flagged: boolean) {
    this.splitButtonItems = [
      {
        label: flagged ? 'Unflag content' : 'Flag content',
        command: () => {
          flagged ? this.unflagDocument() : this.toggleDialog(DialogState.FLAG);
        },
      },
      {
        label: 'Edit description',
        command: () => {
          this.toggleDialog(DialogState.DESCRIPTION);
        },
      },
    ];
    if (!this.hasRights || this.sidePanel.file?.is_locked) {
      this.splitButtonItems = this.splitButtonItems.slice(0, 1);
    }
  }

  async getVerifiedHistory(documentId: string) {
    this.versioningService.getHistory(documentId).subscribe({
      next: (response: VerifiedVersion) => {
        this.sidePanel.verifiedHistory = response;
        this.sidePanel.verifiedHistory.history.versions = this.orderVersioning(
          response.history.versions,
        );
        this.unsetFromLoadingSection([Sections.VERSIONING]);
      },
    });
  }

  orderVersioning(versions: Version[]): Version[] {
    return versions.sort((a, b) => {
      if (!a.version) return -1;
      if (!b.version) return 1;
      return b.version - a.version;
    });
  }

  toggleDialog(dialogState: DialogState) {
    this.dialogToDisplay = dialogState;
  }
}
