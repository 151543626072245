import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

/* Translation modules */
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

/* PrimeNG Components */
import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';
import { BadgeModule } from 'primeng/badge';
import { ChipModule } from 'primeng/chip';
import { ChipsModule } from 'primeng/chips';
import { TagModule } from 'primeng/tag';
import { TableModule } from 'primeng/table';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { StyleClassModule } from 'primeng/styleclass';
import { TreeModule } from 'primeng/tree';
import { TooltipModule } from 'primeng/tooltip';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { DropdownModule } from 'primeng/dropdown';
import { ProgressBarModule } from 'primeng/progressbar';
import { MultiSelectModule } from 'primeng/multiselect';
import { DialogModule } from 'primeng/dialog';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { SplitButtonModule } from 'primeng/splitbutton';
import { RatingModule } from 'primeng/rating';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { CardModule } from 'primeng/card';
import { ToastModule } from 'primeng/toast';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CheckboxModule } from 'primeng/checkbox';
import { FloatLabelModule } from 'primeng/floatlabel';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { CalendarModule } from 'primeng/calendar';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { StepsModule } from 'primeng/steps';
import { ListboxModule } from 'primeng/listbox';
import { PasswordModule } from 'primeng/password';
import { SkeletonModule } from 'primeng/skeleton';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TimelineModule } from 'primeng/timeline';
import { PanelModule } from 'primeng/panel';
import { TabViewModule } from 'primeng/tabview';
import { TabMenuModule } from 'primeng/tabmenu';
import { RadioButtonModule } from 'primeng/radiobutton';
import { MenuModule } from 'primeng/menu';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputTextModule } from 'primeng/inputtext';

/* Components */
import { ChipComponent } from './components/chip/chip.component';
import { TopBarComponent } from './components/top-bar/top-bar.component';
import { NgTableComponent } from './components/ng-table/ng-table.component';
import { SideBarNavigationComponent } from './components/side-bar-navigation/side-bar-navigation.component';
import { IconComponent } from './components/icon/icon.component';
import { CustomSharedDrivesComponent } from './pages/settings/custom-shared-drives/custom-shared-drives.component';

/* Guards */
/* Pages */
import { TodoComponent } from './pages/todo/todo.component';
import { VerifiedDocsComponent } from './pages/verified-docs/verified-docs.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { LoginComponent } from './pages/login/login.component';
import { FolderContentComponent } from './pages/folder-content/folder-content.component';
import { SearchResultsComponent } from './pages/search-results/search-results.component';
import { FooterComponent } from './components/footer/footer.component';
import { SidePanelComponent } from './pages/side-panel/side-panel.component';
import { TrackerComponent } from './components/tracker/tracker.component';
import { LoadingComponent } from './components/loading/loading.component';
import { OnboardingComponent } from './pages/onboarding/onboarding.component';
import { SetupOnboardingComponent } from './components/setup-onboarding/setup-onboarding.component';
import { SidePanelTitleComponent } from './pages/side-panel/title/side-panel-title.component';
import { SidePanelContributorsComponent } from './pages/side-panel/contributors/side-panel-contributors.component';
import { SidePanelTagsComponent } from './pages/side-panel/tags/side-panel-tags.component';
import { SidePanelRatingsComponent } from './pages/side-panel/ratings/side-panel-ratings.component';
import { SidePanelActivityComponent } from './pages/side-panel/activity/side-panel-activity.component';
import { SidePanelVersioningComponent } from './pages/side-panel/versioning/side-panel-versioning.component';
import { SidePanelLabelsComponent } from './pages/side-panel/labels/side-panel-labels.component';
import { SidePanelDescriptionComponent } from './pages/side-panel/description/side-panel-description.component';
import { SidePanelFooterComponent } from './pages/side-panel/footer/side-panel-footer.component';
import { SidePanelStatusScreensComponent } from './pages/side-panel/status-screens/side-panel-status-screens.component';
import { SidePanelDialogsComponent } from './pages/side-panel/dialogs/side-panel-dialogs.component';
import { PropertiesDialogComponent } from './pages/side-panel/dialogs/properties/properties-dialog.component';
import { RatingDialogsComponent } from './pages/side-panel/dialogs/rating/rating-dialogs.component';
import { DescriptionDialogComponent } from './pages/side-panel/dialogs/description/description-dialog.component';
import { FlagDialogComponent } from './pages/side-panel/dialogs/flag/flag-dialog.component';
import { VersioningDialogComponent } from './pages/side-panel/dialogs/versioning/versioning-dialog.component';
import { WorkflowApprovalDialogComponent } from './pages/side-panel/dialogs/workflow-approval/workflow-approval-dialog.component';
import { SensitivityComponent } from './pages/side-panel/dialogs/sensitivity/sensitivity.component';
import { CustomPropertiesComponent } from './pages/settings/custom-properties/custom-properties.component';
import { ListCustomPropertiesComponent } from './pages/settings/custom-properties/list/list.component';
import { CreateCustomPropertyComponent } from './pages/settings/custom-properties/create/create.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { NavbarComponent } from './pages/settings/navbar/navbar.component';
import { InstallationComponent } from './pages/settings/installation/installation.component';
import { PeopleComponent } from './pages/settings/custom-properties/list/people/people.component';
import { TabsWithSectionsComponent } from './components/tabs-with-sections/tabs-with-sections.component';
import { SharedDriveSelectorComponent } from './components/shared-drive-selector/shared-drive-selector.component';
import { SuggestedTagComponent } from './components/suggested-tag/suggested-tag.component';

/* Services */

@NgModule({
  declarations: [
    AppComponent,
    TodoComponent,
    VerifiedDocsComponent,
    NotFoundComponent,
    ChipComponent,
    SideBarNavigationComponent,
    NgTableComponent,
    TopBarComponent,
    LoginComponent,
    FolderContentComponent,
    SearchResultsComponent,
    FooterComponent,
    SidePanelComponent,
    TrackerComponent,
    OnboardingComponent,
    SetupOnboardingComponent,
    SidePanelTitleComponent,
    SidePanelContributorsComponent,
    SidePanelTagsComponent,
    SidePanelRatingsComponent,
    SidePanelActivityComponent,
    SidePanelVersioningComponent,
    SidePanelLabelsComponent,
    SidePanelDescriptionComponent,
    SidePanelFooterComponent,
    SidePanelStatusScreensComponent,
    SidePanelDialogsComponent,
    PropertiesDialogComponent,
    RatingDialogsComponent,
    DescriptionDialogComponent,
    FlagDialogComponent,
    VersioningDialogComponent,
    WorkflowApprovalDialogComponent,
    IconComponent,
    SensitivityComponent,
    CustomSharedDrivesComponent,
    CustomPropertiesComponent,
    ListCustomPropertiesComponent,
    CreateCustomPropertyComponent,
    SettingsComponent,
    NavbarComponent,
    InstallationComponent,
    TabsWithSectionsComponent,
    PeopleComponent,
    SharedDriveSelectorComponent,
    SuggestedTagComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) =>
          new TranslateHttpLoader(http, './assets/i18n/', '.json'),
        deps: [HttpClient],
      },
    }),
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    AvatarModule,
    ButtonModule,
    TagModule,
    TableModule,
    ConfirmDialogModule,
    StyleClassModule,
    TreeModule,
    TooltipModule,
    ProgressSpinnerModule,
    AutoCompleteModule,
    BreadcrumbModule,
    DropdownModule,
    ProgressBarModule,
    MultiSelectModule,
    DialogModule,
    InputGroupModule,
    InputGroupAddonModule,
    BadgeModule,
    SplitButtonModule,
    RatingModule,
    InputTextareaModule,
    ChipModule,
    ChipsModule,
    CardModule,
    ToastModule,
    CheckboxModule,
    FloatLabelModule,
    LoadingComponent,
    AvatarGroupModule,
    CalendarModule,
    InputSwitchModule,
    OverlayPanelModule,
    StepsModule,
    ListboxModule,
    PasswordModule,
    SkeletonModule,
    TimelineModule,
    PanelModule,
    TabViewModule,
    TabMenuModule,
    PanelModule,
    RadioButtonModule,
    MenuModule,
    IconFieldModule,
    InputIconModule,
    InputTextModule,
  ],
  providers: [
    MessageService,
    ConfirmationService,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
