import { File } from './file.types';

export interface UnprocessedEntity<T> {
  /** The id of the entity. */
  id: T;

  /** The reason why the entity was not processed. */
  reason?: string;
}

export interface BulkResponse<T, R> {
  /** Successfully processed entities. */
  processed: T[];

  /** Entities that were not processed. */
  failed?: UnprocessedEntity<R>[];
}

export interface BulkWorkflowApproveResponse
  extends BulkResponse<File, string> {
  /** Number of files that have been indexed to AI **/
  nbProcessedIndexations: number;
}

export interface UpdateFilePropertiesOptions {
  /** The file ids to process. */
  file_ids: string[];

  /** The document type to apply to the files. */
  document_type?: string;

  /** The language to apply to the files. */
  language?: string;

  /** The description to apply to the files. */
  description?: string;
}

export interface UpdateFilesTagsOptions {
  /** The file ids to process. */
  file_ids: string[];

  /** The tag ids to apply to the files. */
  tag_ids: number[];

  /** The tag to set as ignore to the file */
  tags_to_ignore: number[];

  /** The operation to apply to the tags. */
  operation: UpdateTagsOperation;
}

export interface UpdateFilesConfidentialityOptions {
  /** The file ids to process. */
  file_ids: string[];

  /** The confidentiality to apply to the files. */
  confidentiality?: string;
}

export enum UpdateTagsOperation {
  /** Merge given tags with existing one */
  MERGE = 'merge',

  /** Replace existing tags with given ones */
  REPLACE = 'replace',
}
