<form
  [formGroup]="customLabelForm"
  novalidate
  class="container"
  (ngSubmit)="onSubmit()"
>
  <div class="p-fluid">
    <p-floatLabel>
      <input pInputText id="name" formControlName="name" />
      <label for="name">Type the name of your custom property</label>
    </p-floatLabel>
  </div>

  <p-panel
    header="1. Choose Property type"
    [toggleable]="true"
    [collapsed]="activeStepIndex !== 0"
  >
    <ng-template pTemplate="headericons" let-collapsed="$implicit">
      @if (collapsed) {
        <i class="pi pi-angle-down"></i>
      } @else {
        <i class="pi pi-angle-up"></i>
      }
    </ng-template>

    <div class="panel-container">
      <p>
        Choose within the following field type the one that correspond to the
        custom property you're about to create:
      </p>

      <div class="panel-container__radio">
        <p-radioButton value="person" inputId="person" formControlName="type" />
        <label for="person" class="ml-2">Person (using G-Directory)</label>
      </div>

      <div class="panel-container__radio">
        <p-radioButton
          value="{{ CustomLabelType.OPTIONS_LIST }}"
          inputId="list"
          formControlName="type"
        />
        <label for="list" class="ml-2">Options list (200 options max.)</label>
      </div>
    </div>

    <div class="cta-container">
      <p-button
        label="Go to next step"
        (onClick)="goToNextStep(0)"
        [disabled]="
          customLabelForm.get('type')?.invalid ||
          customLabelForm.get('name')?.value === ''
        "
        icon="pi pi-angle-right"
        iconPos="right"
      />
    </div>
  </p-panel>

  <p-panel
    header="2. Set-up custom property"
    [toggleable]="true"
    [collapsed]="activeStepIndex !== 1"
  >
    <ng-template pTemplate="headericons" let-collapsed="$implicit">
      @if (collapsed) {
        <i class="pi pi-angle-down"></i>
      } @else {
        <i class="pi pi-angle-up"></i>
      }
    </ng-template>

    <div class="panel-container">
      @if (
        customLabelForm.getRawValue().type === CustomLabelType.OPTIONS_LIST
      ) {
        <div class="panel-container__input">
          <label for="gsheet">G-Sheet URL</label>
          <input
            pInputText
            id="gsheet"
            aria-describedby="gsheet-help"
            formControlName="gsheetUrl"
          />
          <small id="gsheet-help">Options list (200 options max.) </small>

          @if (customLabelForm.controls.gsheetUrl.errors?.['error']) {
            <p class="panel-container__error">
              {{ customLabelForm.controls.gsheetUrl.errors?.['error'] }}
            </p>
          }

          @if (customLabelForm.controls.gsheetUrl.errors?.['warnings']) {
            <div class="panel-container__warning">
              <ul>
                @for (
                  warning of customLabelForm.controls.gsheetUrl.errors?.[
                    'warnings'
                  ];
                  track $index
                ) {
                  <li>{{ warning }}</li>
                }
              </ul>
            </div>
          }
        </div>
      }

      <div class="panel-container__radio">
        <p-checkbox
          [binary]="true"
          inputId="multiselect"
          formControlName="allowMultiSelect"
        />
        <label for="multiselect"
          >Allow multiple selections (users can select no more than 10
          options)</label
        >
      </div>
    </div>

    <div class="cta-container">
      <p-button
        label="Go to previous step"
        (click)="goToPreviousStep(1)"
        icon="pi pi-angle-left"
        [outlined]="true"
        severity="secondary"
      />
      <p-button
        label="Go to final step"
        (onClick)="goToNextStep(1)"
        [disabled]="isFinalStepDisabled()"
        icon="pi pi-angle-right"
        iconPos="right"
      />
    </div>
  </p-panel>

  <p-panel
    header="3. User availability"
    [toggleable]="true"
    [collapsed]="activeStepIndex !== 2"
  >
    <ng-template pTemplate="headericons" let-collapsed="$implicit">
      @if (collapsed) {
        <i class="pi pi-angle-down"></i>
      } @else {
        <i class="pi pi-angle-up"></i>
      }
    </ng-template>

    <div class="panel-container">
      <p>
        Choose which users will be able to use (when having write access on the
        file) this custom property:
      </p>

      <div class="panel-container__input">
        <span class="p-fluid">
          <p-autoComplete
            #autoComplete
            (onFocus)="autoComplete.show()"
            (onSelect)="getDirectorySuggestions()"
            formControlName="permissions"
            [suggestions]="directorySuggestions"
            (completeMethod)="getDirectorySuggestions($event)"
            optionLabel="fullname"
            [multiple]="true"
            placeholder="Start typing a user name, email or group"
        /></span>
        <small id="gsheet-help"
          >Leave this field empty to make this property usable by anyone at your
          company (when having write access on the file).</small
        >
      </div>
    </div>

    <div class="cta-container">
      <p-button
        label="Go to previous step"
        (click)="goToPreviousStep(2)"
        icon="pi pi-angle-left"
        [outlined]="true"
        severity="secondary"
      />
      <p-button
        label="{{ customLabelId ? 'Update' : 'Create' }} custom property"
        [loading]="submitting"
        type="submit"
      />
    </div>
    @if (submitError) {
      <div class="submit-error">{{ submitError }}</div>
    }
  </p-panel>

  <p-confirmDialog>
    <ng-template pTemplate="message" let-message>
      <p>{{ message.message }}</p>
    </ng-template>
  </p-confirmDialog>
</form>
