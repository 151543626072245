import { Component, EventEmitter, Input, Output, OnChanges } from '@angular/core';
import { DialogState } from 'app/pages/side-panel/side-panel.types';
import { FileLabel, LabelType, TagDatamart } from 'app/types';

@Component({
  selector: 'side-panel-tags',
  templateUrl: './side-panel-tags.component.html',
  styleUrl: './side-panel-tags.component.scss',
})
export class SidePanelTagsComponent implements OnChanges {
  @Input() language?: string = undefined;
  @Input() loading: boolean = false;
  @Input() confidentiality?: string = undefined;
  @Input() documentType?: string = undefined;
  @Input() tags?: TagDatamart[] = [];
  @Input() locked?: boolean = false;
  @Input() hasRights: boolean = false;
  @Input() customProperties?: FileLabel[] = [];
  @Output() editPropertiesCallback = new EventEmitter();
  @Output() ignoreSuggestedTagCallback = new EventEmitter<
    TagDatamart | undefined
  >();

  readonly DialogState = DialogState;

  constructor() {}

  ngOnChanges() {
    this.customProperties = this.customProperties?.filter(
      (fileLabel) => fileLabel.label.type === LabelType.CUSTOM,
    );
  }
  
  ignoreSuggestedTag(tag: TagDatamart | undefined) {
    this.ignoreSuggestedTagCallback.emit(tag);
  }
}
