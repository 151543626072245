import { Component, OnInit } from '@angular/core';
import { CustomLabelService } from 'app/services/custom-label/custom-label.service';
import { CustomLabel } from 'app/types';
import { ConfirmationService, MenuItem } from 'primeng/api';

@Component({
  selector: 'app-custom-properties-list',
  templateUrl: './list.component.html',
  styleUrl: './list.component.scss',
})
export class ListCustomPropertiesComponent implements OnInit {
  customLabels: CustomLabel[] = [];
  lineMenuItems!: Record<string, MenuItem[]>;
  loading: boolean = true;

  constructor(
    private customLabelService: CustomLabelService,
    private confirmationService: ConfirmationService,
  ) {}

  ngOnInit() {
    this.customLabelService.getCustomLabels().subscribe((customLabels) => {
      this.customLabels = customLabels;
      this.loading = false;

      // We need to generate one menu item for each custom property
      // so that the command gets the correct context
      this.lineMenuItems = customLabels.reduce(
        (map, prop) => {
          map[prop.id!] = [
            {
              label: 'Edit',
              icon: 'pi pi-pencil',
              routerLink: `../edit/${prop.id}`,
            },
            {
              label: 'Delete',
              icon: 'pi pi-trash',
              command: () => {
                const customLabel: CustomLabel | undefined =
                  this.customLabels.find((prop) => prop.id === prop.id);
                if (customLabel) {
                  this.deleteCustomLabel(prop);
                }
              },
            },
          ];
          return map;
        },
        {} as Record<string, MenuItem[]>,
      );
    });
  }

  deleteCustomLabel(customLabel: CustomLabel) {
    this.confirmationService.confirm({
      message:
        'This property will be removed from all files previously applied and removed from search shortly thereafter. This action cannot be undone.',
      header: `Delete "${customLabel.name}" property?`,
      acceptIcon: 'none',
      acceptLabel: 'Delete permanently',
      acceptButtonStyleClass: 'p-button-danger',
      rejectIcon: 'none',
      rejectLabel: 'Cancel',
      rejectButtonStyleClass: 'p-button-secondary p-button-outlined',
      accept: () => {
        return this.customLabelService
          .deleteCustomLabel(customLabel.id!)
          .subscribe(() => {
            this.customLabels = this.customLabels.filter(
              (prop) => prop.id !== customLabel.id,
            );
          });
      },
      reject: () => {},
    });
  }
}
