<div class="apply-properties">
  <p-autoComplete
    [suggestions]="searchedTags"
    optionLabel="label"
    [multiple]="true"
    (completeMethod)="searchTags($event)"
    [(ngModel)]="tagsInput"
    [virtualScroll]="true"
    [virtualScrollItemSize]="30"
    placeholder="Select tags"
  >
    <ng-template let-tag pTemplate="item">
      @if (tag.id !== -1) {
        <div class="tag-selector">
          <i class="pi pi-tags"></i>
          <span>{{ tag.label }}</span>
        </div>
      } @else {
        <div class="tag-selector blue">
          <i class="pi pi-plus-circle"></i>
          <span>{{ tag.label }}</span>
        </div>
      }
    </ng-template>
  </p-autoComplete>
  <p-dropdown
    [options]="confidentialityOptions"
    [(ngModel)]="confidentialitiesInput"
    placeholder="Confidentiality"
    class="dropdown-width"
  />
  <p-dropdown
    [options]="documentTypeOptions"
    [(ngModel)]="documentTypeInput"
    placeholder="Document type"
    class="dropdown-width"
  />
  <p-dropdown
    [options]="LanguageOptions"
    [(ngModel)]="languageInput"
    placeholder="Language"
    class="dropdown-width"
  />
  @if (hasRight) {
    @for (customLabel of customLabels; track customLabel) {
      @if (customLabel.options?.length) {
        @if (customLabel.allowMultiSelect) {
          <p-autoComplete
            [suggestions]="
              searchedCustomLabelsOptions.get(customLabel.label_field.name) ??
              []
            "
            optionLabel="name"
            [multiple]="true"
            (completeMethod)="searchCustomLabels($event, customLabel)"
            [(ngModel)]="selectedCustomLabelsOptions[$index]"
            [virtualScroll]="true"
            [virtualScrollItemSize]="30"
            [placeholder]="customLabel.label_field.name"
          >
            <ng-template let-custom pTemplate="item">
              <div class="tag-selector">
                <i class="pi pi-tags"></i>
                <span>{{ custom?.name }}</span>
              </div>
            </ng-template>
          </p-autoComplete>
        } @else {
          <p-dropdown
            [options]="customLabel.options"
            optionLabel="name"
            [(ngModel)]="selectedCustomLabelsOptions[$index]"
            [placeholder]="customLabel.label_field.name"
            class="dropdown-width"
          >
            <ng-template let-custom pTemplate="item">
              <div class="tag-selector">
                <i class="pi pi-tags"></i>
                <span>{{ custom?.name }}</span>
              </div>
            </ng-template>
          </p-dropdown>
        }
      }
    }
  }
  <diV class="properties-buttons">
    <p-button
      label="Cancel"
      severity="secondary"
      (mousedown)="cancelPropertiesDialog()"
    />
    <p-button
      label="Save changes"
      (mousedown)="
        savePropertiesDialog({
          tags: tagsInput,
          confidentiality: confidentialitiesInput,
          documentType: documentTypeInput,
          language: languageInput,
          customPropertiesOptions: getSelectedCustomLabels(),
        })
      "
    />
  </diV>
</div>
