import {
  Tag,
  User,
  VerifiedVersion,
  File,
  Workflow,
  CustomLabel,
  LabelOption,
} from 'app/types';

export enum Heartbeat {
  APP_HEARTBEAT = 'PRESENCEDOC_APP_HEARTBEAT',
  EXTENSION_HEARTBEAT = 'PRESENCEDOC_EXTENSION_HEARTBEAT',
  EXTENSION_DOCUMENT = 'PRESENCEDOC_EXTENSION_DOCUMENT',
}

export enum VersioningOptions {
  DRAFT = 'Duplicate file as Version draft',
  DRAFT_FROM_FILE = 'Add version from existing file',
}

export enum Status {
  FILLED = 'FILLED',
  MY_DRIVE = 'MY_DRIVE',
  FILE_NOT_WATCHED = 'FILE_NOT_WATCHED',
  LOADING = 'LOADING',
  UNKNOWN = 'UNKNOWN',
  NOT_FOUND = 'NOT_FOUND',
  SECRET_FILE = 'SECRET_FILE',
  OVERLAYER_NOT_ENOUGH_PERMISSION = 'OVERLAYER_NOT_ENOUGH_PERMISSION',
}

export enum DialogState {
  DESCRIPTION = 'DESCRIPTION',
  PROPERTIES = 'PROPERTIES',
  RATING = 'RATING',
  FLAG = 'FLAG',
  VERSIONING = 'VERSIONING',
  WORKFLOW = 'WORKFLOW',
  SECRET = 'SECRET',
  NONE = 'NONE',
}

export enum Sections {
  TITLE = 'TITLE',
  LABELS = 'LABELS',
  CONTRIBUTORS = 'CONTRIBUTORS',
  DESCRIPTION = 'DESCRIPTION',
  PROPERTIES = 'PROPERTIES',
  WORKFLOW = 'WORKFLOW',
  VERSIONING = 'VERSIONING',
  LIKE = 'LIKE',
  RATINGS = 'RATINGS',
}

export interface LikeButtonData {
  likedByMe: boolean;
  likesCount: number;
  onClick: () => void;
}

export interface SidePanel {
  connectedUser?: User;
  status: Status;
  loadingSections: Sections[];
  tags?: Tag[];
  file?: File;
  workflows?: Workflow[];
  verifiedHistory?: VerifiedVersion;
  customLabels?: CustomLabel[];
}

export interface SidePanelProperties {
  tags: Tag[];
  confidentiality: string;
  documentType: string;
  language: string;
  customPropertiesOptions: LabelOption[];
}
